import React from "react"
import PropTypes from "prop-types";

const Hero = props => {
  const { config } = props

  //console.log(props);
  return (
    <React.Fragment>
      <div className="hero">
        <div className="hero-text">
          <h1>This is</h1>
          <h1>{config.headerTitle}</h1>
          <h2>{config.headerSubTitle}</h2>
        </div>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          height: 90vh;
          min-height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .hero-text {
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
          width: 95vw;;
        }

        .hero-text :global(h1) {
          font-weight: 700;
          font-size: 6rem;
          letter-spacing: 0.3rem;
          animation: header1 2s 1 ease-out;
        }

        .hero-text :global(h2) {
          margin-top 5vh;
          font-weight: 400;
          font-style: italic;
          letter-spacing: 0.3rem;
          animation: appear 2.5s 1 ease-out;
        }

        @keyframes header1 {
          from {
            color: transparent;
            transform: translateY(150%);
          }
          to {
            transform: translateY(0);
          }
        }

        @keyframes appear {
          0% {
            color: transparent;
          }
          80% {
            color: transparent;
          }
        }

        @media (max-width: 510px) {
          .hero-text :global(h1) {
            font-size: 4rem;
          }

          .hero-text :global(h2) {
            font-size: 1.2rem;
          }
        }

        
      `}</style>
    </React.Fragment>
  )
}

Hero.propTypes = {
  config: PropTypes.object.isRequired
}


export default Hero

/*
 .hero {
                    background-image: url(${backgrounds.mobile});
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                    padding-bottom: calc(700px + (474 - 700) * (100vw - 600px) / (1200 - 600));
                    padding-top: ${theme.header.height.homepage};
                    margin-bottom: ${theme.space.xl}
                }

                .hero:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    content: '';
                    z-index:-1;
                }

                .hero-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    text-align: center;
                    z-index:0;
                    background-color: rgba(0, 0, 0, 0.3);
                    width: 100%;
                }

                @media (max-width: 600px) { 
                    .hero {
                        padding-bottom: 700px;
                    }
                }

                @media (min-width: 700px) { 
                    .hero {
                        background-image: url(${backgrounds.tablet});
                    }
                }
                  
                @media (min-width: 1200px) {
                    .hero {
                        padding-bottom: 40%;
                        background-image: url(${backgrounds.desktop});
                    }
                }
                */
