import { FaArrowRight } from "react-icons/fa/";
import { FaCalendar } from "react-icons/fa/";
//import { FaTag } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import PropTypes from "prop-types";

const blogItem = props => {
    const {
      theme,
      post: {
        //excerpt,
        fields: { slug },
        frontmatter: {
          title,
          date,
          author,
          category,
          description,
          featuredImage: {
            childImageSharp: { fluid }
          }
        }
      }
    } = props;

    return (
        <React.Fragment>
            <li>
                <Link to={slug} key={slug} className="link">
                    <div className="gatsby-image-outer-wrapper">
                        <Img fluid={fluid} />
                    </div> 
                    <p className="category">
                        <span>
                            {category}
                        </span>
                    </p>
                    <h1>
                        {title} <FaArrowRight className="arrow" />
                    </h1>
                    <p className="meta">
                        <span>
                            <FaCalendar size={18} /> {date}
                        </span>
                        <span>
                            <FaUser size={18} /> {author}
                        </span>              
                    </p>
                    <p>{description}</p>
                </Link>
            </li>

            {/* --- STYLES --- */}
            <style jsx>{`

                li {
                    border: 1px solid transparent;
                    border-radius: ${theme.size.radius.default};
                    z-index: 0;
                    margin-bottom: calc( ${theme.space.xl} * 1.5 );
                }

                li :global(.gatsby-image-outer-wrapper) {
                                       
                    overflow: hidden;
                    z-index: 0;
                }

                li :global(.gatsby-image-outer-wrapper img) {
                    z-index: -1;
                }

                li :global(a) {
                    transition: all 0.3s ease 0s;
                }

                li:hover :global(a) {
                    color: black;
                }

                li:hover :global(.gatsby-image-outer-wrapper) {
                    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.5);
                    transition: all 0.3s ease 0s;
                }

                .category {
                    margin-top: -${theme.space.m};
                    z-index: 1;
                }

                .category span {
                    color: white;
                    text-transform: uppercase;
                    font-size: 0.8em;
                    font-weight: 200;
                    background-color: ${theme.color.neutral.category_blue};
                    margin-left: ${theme.space.l};
                    padding: ${theme.space.s} ${theme.space.m};
                }

                h1 {
                    padding: ${theme.space.m} ${theme.space.s} 0;
                    line-height: ${theme.blog.h1.lineHeight};
                    font-size: ${theme.blog.h1.size};
                }

                .meta {
                    display: flex;
                    flex-flow: row wrap;
                    font-size: 0.8em;
                    padding: ${theme.space.m} ${theme.space.m};
                    background: transparent;
                }

                .meta :global(svg) {
                    fill: ${theme.color.neutral.category_blue};
                    margin: ${theme.space.inline.xs};
                }
                
                .meta span {
                    align-items: center;
                    display: flex;
                    text-transform: uppercase;
                    margin: ${theme.space.xs} ${theme.space.m} ${theme.space.xs} 0;
                }
                
                p {
                    line-height: 1.5;
                    padding: 0 ${theme.space.s};
                    text-remove-gap: both;
                }

                

            `}</style>

        </React.Fragment>
    );
};

blogItem.propTypes = {
    theme: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired
}

export default blogItem;
