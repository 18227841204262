import React from "react"
import { graphql } from "gatsby"

import Hero from "../components/hero/hero"
import Blog from "../components/blog/blog"
import Layout from "../layouts/layout"
import themeObject from "../theme/theme.yaml"
import config from "../../content/meta/config"
import SEO from "../components/seo/seo"
import PropTypes from "prop-types"

class IndexPage extends React.Component {
  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        site: {
          siteMetadata: { title, description },
        },
      },
    } = this.props

    return (
      <React.Fragment>
        <SEO title={title} description={description} />
        <Layout>
          <div>
            <Hero config={config} />
          </div>
          
          <div className="blogContainer">            
            <Blog posts={posts} theme={themeObject} />
          </div>
        </Layout>

        <style jsx>{`
          .blogContainer {
            position: relative;
            background-color: ${themeObject.color.background.white_opac};
            z-index: 0;
            width: 95vw;
            max-width: ${`calc(${themeObject.text.maxWidth.desktop} *2)`};
            margin: 0 auto ${themeObject.space.xl};
          }

          
        `}</style>
      </React.Fragment>
    )
  }
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { status: { eq: "public" } }
        fileAbsolutePath: { regex: "//blog/[0-9]+.*-/" }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            author
            category
            title
            description
            date(formatString: "DD.MM.YYYY")
            status
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

/*

<div className="gradient" />
.gradient {
            height: 200px;
            position: relative;
            width: 95vw;
            max-width: ${`calc(${themeObject.text.maxWidth.desktop} *2)`};
            margin: 0 auto;
            background: linear-gradient(0deg, ${themeObject.color.background.white_opac} 10%, rgba(255,255,255,0) 100%);
          }

background: linear-gradient(0deg, ${themeObject.color.background.white_opac} 80%, rgba(255,255,255,0) 100%);
featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }

background: linear-gradient(0deg, ${themeObject.color.background.white_opac} 80%, rgba(255,255,255,0) 100%);


bgDesktop: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgs: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      fluid(maxWidth: 1240) {
        ...GatsbyImageSharpFluid
      }
    }
    */
