import React from "react";
import PropTypes from "prop-types";
import BlogItem from "./blogItem";

const Blog = props => {

    const { posts, theme } = props;
    return (
        <React.Fragment>
            <main className="main">
                <ul>
                    {posts.map(post => {
                        const {
                            node,
                            node: {
                                fields: { slug }
                            }
                        } = post;
                    return <BlogItem key={slug} theme={theme} post={node} />;
                    })}
                </ul>
            </main>

            {/* --- STYLES --- */}
            <style jsx>{`
                .main {
                    padding: ${theme.space.xl} ${theme.space.m} ${theme.space.m} ${theme.space.m};
                }

                ul {
                    list-style: none;
                    margin: 0 auto;
                    padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
                    max-width: ${theme.text.maxWidth.desktop};
                }
            `}</style>

        </React.Fragment>
    );
};

Blog.propTypes = {
    theme: PropTypes.object.isRequired,
    posts: PropTypes.array.isRequired
}

export default Blog;